import process from '@/utils/propertyFileProcessor';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
/* eslint-disable */
// @ts-ignore
import deProperties from '../../trespa/i18n/module-trespa-frontend_de.properties';
// @ts-ignore
import enProperties from '../../trespa/i18n/module-trespa-frontend_en.properties';
// @ts-ignore
import esProperties from '../../trespa/i18n/module-trespa-frontend_es.properties';
// @ts-ignore
import frProperties from '../../trespa/i18n/module-trespa-frontend_fr.properties';
// @ts-ignore
import itProperties from '../../trespa/i18n/module-trespa-frontend_it.properties';
// @ts-ignore
import nlProperties from '../../trespa/i18n/module-trespa-frontend_nl.properties';
// @ts-ignore
import plProperties from '../../trespa/i18n/module-trespa-frontend_pl.properties';
// @ts-ignore
import ptProperties from '../../trespa/i18n/module-trespa-frontend_pt.properties';
// @ts-ignore
import zhProperties from '../../trespa/i18n/module-trespa-frontend_zh.properties';
// @ts-ignore
import en_usProperties from '../../trespa/i18n/module-trespa-frontend_en-US.properties';
/* eslint-enable */

const de = process(deProperties);
const es = process(esProperties);
const fr = process(frProperties);
const it = process(itProperties);
const en = process(enProperties);
const nl = process(nlProperties);
const pl = process(plProperties);
const pt = process(ptProperties);
const zh = process(zhProperties);
const en_US = process(en_usProperties);

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: _mgnl.language,
    fallbackLocale: 'en',
    messages: {
        en,
        de_DE: de,
        de_CH: de,
        es_ES: es,
        es_CL: es,
        fr_BE: fr,
        fr_FR: fr,
        fr_CH: fr,
        it_CH: it,
        it_IT: it,
        nl_NL: nl,
        nl_BE: nl,
        pl_PL: pl,
        pt_PT: pt,
        pt_BR: pt,
        zh_CN: zh
    }
});

export default i18n;
